import { Dealer } from "../global"

export class DealersClient {
  private static url: string = `https://${process.env.GATSBY_STAGING_API}/v1/dealers`
  private static key: string = process.env.GATSBY_STAGING_API_KEY

  /**
   * Makes an arbitrary call to the Dealers API
   *
   * @param {string} endpoint - The endpoint to call beyond the base URL
   * @param {"GET"|"POST"|"DELETE"|"UPDATE"} method - Which HTTP method to use
   * @param {any} params - Key/value object that will be converted to URL query params in the call
   *
   * @returns {Promise<T>} - JSON response from the API
   */
  private static fetch = async <T>(
    method: "GET" | "POST" | "DELETE" | "UPDATE",
    endpoint?: string,
    params?: any
  ): Promise<T> => {
    const url = `${this.url}${endpoint ? "/" + endpoint : ""}${
      params ? "?" + new URLSearchParams(params) : ""
    }`

    const config = {
      method: method,
      headers: {
        "x-api-key": this.key,
      },
    }

    try {
      const res = await fetch(url, config)
      const json: T = await res.json()
      return json
    } catch (error) {
      console.error(`Couldn't fetch dealers.`, error)
    }
  }

  /**
   * Get n number of dealers in radius
   *
   * @param {number} zip - The ZIP code to search in
   * @param {number} radius - The radius around the ZIP code to search
   * @param {number} take - How many dealers to return
   *
   * @returns {Dealer[]} - Array of dealers
   */
  public static getForLocation = async (
    zip: number,
    radius: number = 25,
    take?: number,
    dealerCode?: string
  ): Promise<any[]> => {
    let params: any = {
      zip,
      radius,
    }

    if (take) {
      params.take = take
    }

    if (dealerCode) {
      params.dealerCode = dealerCode
    }

    return await this.fetch<Dealer[]>("GET", "forlocation", params)
  }

  /**
   * Get all dealers (Warning: could be a HUGE payload.)
   *
   * @returns {Dealer[]} - Array of dealers
   */
  public static getAll = () => {
    return this.fetch<Dealer[]>("GET")
  }

  /**
   * Gets data for a single dealer by their dealer code
   *
   * @param dealerCode - The individual dealer code to return info for
   *
   * @returns {Dealer} - A single dealer
   */
  public static getByDealerCode = (dealerCode: string) => {
    return this.fetch<Dealer>("GET", "", { dealerCode })
  }
}
