import { ACTION_TYPES } from "./actions"
import { ContactContextState } from "./contact.d"
import { defaultState } from "./context"

const contactReducer = (
  state: ContactContextState,
  action: { type: ACTION_TYPES; payload?: any }
) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CONTACT_DEALER_MODAL_OPEN:
      return {
        ...state,
        isContactDealerModalOpen: action.payload?.open,
        contactDealerSelection: action.payload?.contactDealerSelection,
        vehicle: action.payload?.vehicle,
        seriesName: action?.payload?.seriesName,
        trim: action?.payload?.trim,
        leadType: action.payload?.leadType,
        contactButtonType: action.payload?.contactButtonType,
        confirmContactDealerSelection:
          action.payload?.confirmContactDealerSelection,
      }
    case ACTION_TYPES.SET_TACT_CONTACT_DEALER_MODAL_OPEN:
      return {
        ...state,
        isTactContactDealerModalOpen: action.payload?.open,
        contactDealerSelection: action.payload?.contactDealerSelection,
        leadType: action.payload?.leadType,
      }
    case ACTION_TYPES.SET_FAVORITES_CONTACT_DEALER_MODAL_OPEN:
      return {
        ...state,
        isFavoritesContactDealerModalOpen: action.payload?.open,
        leadType: action.payload?.leadType,
      }
    case ACTION_TYPES.SET_ACTIVE_VIEW:
      return {
        ...state,
        activeView: action.payload,
      }
    case ACTION_TYPES.SET_PREVIOUS_VIEW:
      return {
        ...state,
        previousView: action.payload,
      }
    case ACTION_TYPES.UPDATE_FIELD:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    case ACTION_TYPES.CLEAR_STATE:
      return {
        ...defaultState,
      }
    default:
      return state
  }
}

export default contactReducer
