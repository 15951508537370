import { ACTION_TYPES } from "./actions"
import { SearchState } from "./search.d"

const searchReducer = (
  state: SearchState,
  action: { type: ACTION_TYPES; payload?: any }
) => {
  switch (action.type) {
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        query: action.payload,
      }
    case "SET_SEARCH_ENTRY":
      return {
        ...state,
        entry: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}

export default searchReducer
