import { FavoritesState } from "./favorites.d"
import { ACTION_TYPES } from "./actions"

export const defaultState: FavoritesState = {
  favoritedVehicles: [],
  favoritedDealer: null,
  favoritedOffers: [],
  favoritedAccessories: [],
  favoriteMessage: "default",
  shouldShowTooltip: false,
}

const favoritesReducer = (
  state: FavoritesState,
  action: { type: ACTION_TYPES; payload?: any }
) => {
  switch (action.type) {
    case ACTION_TYPES.ADD_FAVORITED_VEHICLE:
      const existingVehicles = state.favoritedVehicles
        ? state.favoritedVehicles
        : []
      return {
        ...state,
        favoritedVehicles: [...existingVehicles, action.payload],
      }
    case ACTION_TYPES.REMOVE_FAVORITED_VEHICLE:
      const filtered = state.favoritedVehicles.filter(
        vin => vin !== action.payload
      )
      return {
        ...state,
        favoritedVehicles: filtered,
      }
    case ACTION_TYPES.ADD_FAVORITED_ACCESSORY:
      const existingAccessories = state.favoritedAccessories
        ? state.favoritedAccessories
        : []
      return {
        ...state,
        favoritedAccessories: [...existingAccessories, action.payload],
      }
    case ACTION_TYPES.REMOVE_FAVORITED_ACCESSORY:
      const filteredAcc = state.favoritedAccessories.filter(
        acc => acc.code !== action.payload.code
      )
      return {
        ...state,
        favoritedAccessories: filteredAcc,
      }
    case ACTION_TYPES.ADD_FAVORITED_DEALER:
      return {
        ...state,
        favoritedDealer: action.payload,
      }
    case ACTION_TYPES.REMOVE_FAVORITED_DEALER:
      return {
        ...state,
        favoritedDealer: null,
      }
    case ACTION_TYPES.ADD_FAVORITED_OFFER:
      const existingOffers = state.favoritedOffers ? state.favoritedOffers : []
      return {
        ...state,
        favoritedOffers: [...existingOffers, action.payload],
      }
    case ACTION_TYPES.REMOVE_FAVORITED_OFFER:
      const filteredOffers = state.favoritedOffers.filter(
        _id => _id !== action.payload
      )
      return {
        ...state,
        favoritedOffers: filteredOffers,
      }
    case ACTION_TYPES.SET_FAVORITE_MESSAGE:
      return {
        ...state,
        favoriteMessage: action.payload,
        shouldShowTooltip: true,
      }
    case ACTION_TYPES.SET_SHOULD_SHOW_TOOLTIP:
      return {
        ...state,
        shouldShowTooltip: action.payload,
      }
    default:
      throw new Error()
  }
}

export default favoritesReducer
