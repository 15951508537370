import { createContext, useContext, useEffect, useReducer } from "react"
import { ModelComparisonState } from "./modelComparison"
import modelComparisonReducer, { defaultState } from "./reducers"
import { Buffer } from "buffer"
import { seriesNameMap, slugify } from "../../helpers"
import { LanguageContext } from "../Language"

export const ModelComparisonContext = createContext<
  [ModelComparisonState, React.Dispatch<any>]
>([defaultState, () => {}])

export const ModelComparisonProvider: React.FC = ({ children }) => {
  const { language } = useContext(LanguageContext)
  let state = defaultState
  // Check to see if we're in browser
  if (typeof window !== "undefined") {
    // get the ?s= query param
    const params = new URLSearchParams(window.location.search)
    const stateEncoded = params.get("s")
    // if it has a value
    if (stateEncoded) {
      // Decode it from base64 to JSON
      const stateDecoded = Buffer.from(stateEncoded, "base64").toString("utf8")
      const urlState = JSON.parse(stateDecoded)
      // Set the default state to what we pulled from the URL
      if (urlState) {
        state.trims = urlState
      }
      const getSeries =
        language == "en"
          ? location.pathname.replace("/compare", "").split("/")[1]
          : location.pathname.replace("/compare", "").split("/")[2]
      state.model = getSeries ? seriesNameMap.get(getSeries) : ""
    }
    if (
      location.pathname == "/compare/" ||
      location.pathname == "es/compare/"
    ) {
      state.model = ""
      state.trims = []
    }
  }
  const [modelComparisonState, dispatch] = useReducer(
    modelComparisonReducer,
    state
  )
  useEffect(() => {
    const { location, history } = window
    const slugifiedSeries = slugify(modelComparisonState.model)?.replace(
      "-",
      ""
    )
    // Whenever models changes, if we're in browser
    if (
      typeof window !== "undefined" &&
      slugifiedSeries !== undefined &&
      location.pathname.includes("compare")
    ) {
      // Encode the full state to base64
      const stateJsonString = JSON.stringify(modelComparisonState.trims)
      const base64String = Buffer.from(stateJsonString).toString("base64")
      // Set the URL to include that value for the s query param
      const url = `${location.protocol}//${location.host}${location.pathname}?s=${base64String}`
      history.replaceState(null, null, url)
    } else if (
      location.pathname.includes("compare") &&
      location.pathname !== "/compare/"
    ) {
      state.model = ""
      state.trims = []
      history.replaceState(
        null,
        null,
        language == "en"
          ? `${location.protocol}//${location.host}/compare/`
          : `${location.protocol}//${location.host}/es/compare/`
      )
    }
  }, [modelComparisonState])

  return (
    <ModelComparisonContext.Provider value={[modelComparisonState, dispatch]}>
      {children}
    </ModelComparisonContext.Provider>
  )
}
