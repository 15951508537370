import React, { createContext, useContext, useState } from "react"
import {
  ContactDealerData,
  RefineInAction,
  TealiumData,
  TealiumProviderProps,
  initialValue,
  refineInActionDefault,
} from "./tealium.d"

/**
 *
 * @author Stu
 * @summary Creates context that stores tealium data that is set during pageloads, and is consumed by event function calls.
 *
 * @todo Initialize color, model, trim on page load for re-use by multiple events (e.g. Series color selector, 360 view etc)
 */

export const TealiumContext = createContext<TealiumData>(initialValue)

export const TealiumProvider: React.FC<TealiumProviderProps> = ({
  children,
}) => {
  // Shared data about a visited page
  // To Do: Should tealPageData be cleared when component is unmounted?
  const [tealPageData, setTealPageData] = useState({})
  // Shared data about a selected vehicle
  const [vehicleTealData, setVehicleTealData] = useState({})
  // Shared data about inventory results
  const [inventoryResults, setInventoryResults] = useState(null)

  // Shared data about contact dealer values
  const [contactDealerData, setContactDealerData] = useState({
    fromInventory: null,
    fromSelection: null,
    dealerData: null,
  })
  // This state is used to control tags that are sensitive to state changes. It's primarily used on Inventory queries and refinement events
  const [refineInAction, setRefineInAction] = useState<RefineInAction>(
    refineInActionDefault
  )
  // This state is used to create a queue of tags to fire when the filter modal on the inventory page is saved and the refinement event begins
  const [inventoryFilters, setInventoryFilters] = useState([])

  // State for modal events
  const [zipEvent, setZipEvent] = useState(initialValue.zipEvent)

  // Re-writes previous state between page loads (i.e. don't include previous state)
  const writeTealPageData = (newValues: Object) => {
    setTealPageData(() => {
      return { ...newValues }
    })
  }

  const updateTealPageData = (newValues: Object) => {
    setTealPageData(previousData => {
      return { ...previousData, ...newValues }
    })
  }

  const updateVehicleTealData = (newVehicleData: Object) => {
    setVehicleTealData(previousData => {
      return { ...previousData, ...newVehicleData }
    })
  }

  const deleteVehicleTealData = () => {
    setVehicleTealData({})
  }

  const updateContactDealerData = (newContactData: ContactDealerData) => {
    setContactDealerData(previousData => {
      return { ...previousData, ...newContactData }
    })
  }
  const addInventoryFilterToQueue = (newFilter: Object | string) => {
    setInventoryFilters([...inventoryFilters, newFilter])
  }
  const removeInventoryFilterFromQueue = (removeFilter: Object) => {
    const sanitizedFilters = inventoryFilters.filter(
      filter => JSON.stringify(filter) !== JSON.stringify(removeFilter)
    )
    setInventoryFilters(sanitizedFilters)
  }
  const addInventoryPriceFilterToQueue = (
    filter: Object,
    filterKey: string
  ) => {
    const sanitizedFilters = inventoryFilters.filter(filter => {
      return !filter.refinement_value.includes(filterKey)
    })
    setInventoryFilters([...sanitizedFilters, filter])
  }
  const removeInventoryFilterFromQueueByKey = (filterKey: Object) => {
    const sanitizedFilters = inventoryFilters.filter(
      filter => !filter.refinement_value.includes(filterKey)
    )
    setInventoryFilters(sanitizedFilters)
  }
  const swapInventoryFilterInQueue = (filter: Object, filterKey: string) => {
    const sanitizedFilters = inventoryFilters.filter(filter => {
      return !filter.refinement_value.includes(filterKey)
    })
    setInventoryFilters([...sanitizedFilters, filter])
  }
  const clearAllInventoryFilterQueue = () => {
    const radiusFilter = inventoryFilters.find(filter =>
      filter.refinement_value.includes("Radius")
    )
    radiusFilter ? setInventoryFilters([radiusFilter]) : setInventoryFilters([])
  }
  const clearInventoryFilterQueue = () => {
    setInventoryFilters([])
  }

  return (
    <TealiumContext.Provider
      value={{
        tealPageData,
        writeTealPageData,
        updateTealPageData,
        vehicleTealData,
        updateVehicleTealData,
        setVehicleTealData,
        deleteVehicleTealData,
        inventoryResults,
        setInventoryResults,
        contactDealerData,
        updateContactDealerData,
        refineInAction,
        setRefineInAction,
        zipEvent,
        setZipEvent,
        inventoryFilters,
        addInventoryFilterToQueue,
        removeInventoryFilterFromQueue,
        clearInventoryFilterQueue,
        removeInventoryFilterFromQueueByKey,
        swapInventoryFilterInQueue,
        clearAllInventoryFilterQueue,
        addInventoryPriceFilterToQueue,
      }}
    >
      {children}
    </TealiumContext.Provider>
  )
}

export const useTealiumContext = () => useContext(TealiumContext)
