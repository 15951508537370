/* eslint-disable prettier/prettier */
module.exports = {
  mode: "jit",
  darkMode: false, // or 'media' or 'class'
  purge: [],
  theme: {
    fontFamily: {
      sans: ["ToyotaType", "Helvetica Neue", "Arial", "sans-serif"],
    },
    fontWeight: {
      bold: 900,
      black: 800,
      semibold: 600,
      book: 400,
      light: 200,
    },
    colors: {
      toyotaRed: "#eb0a1e",
      black: "#252525",
      blue: "#1779ca",
      facebook: "#4267b2",
      gold: "#ffcd36",
      green: "#338466",
      gunmetal: "#1e262e",
      gunmetalHover: "#47596C",
      instagram: "#5b51d8",
      mystic: "#edf0f5",
      pinterest: "#e60023",
      purple: "#9a47d1",
      twitter: "#1da1f2",
      wildSand: "#f5f5f5",
      white: "#ffffff",
      youtube: "#f00",
      transparent: "transparent",
      red: {
        50: "#f9ced2",
        100: "#f39da5",
        200: "#ed6c77",
        300: "#e73b4a",
        400: "#e10a1d",
        500: "#b40817",
        600: "#870611",
        700: "#5a040c",
        800: "#2d0206",
        900: "#260000",
      },
      gray: {
        50: "#f8f9fa",
        100: "#F2F2F2",
        200: "#e8ecef",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#acb5bd",
        600: "#858e96",
        700: "#495057",
        800: "#343a40",
        900: "#22252b",
      },
      danger: {
        100: "#ffefec",
        200: "#ff1424",
        400: "#f70008",
        700: "#c60000",
      },
      info: {
        100: "#e5f5fd",
        200: "#009fdb",
        400: "#007cc8",
        700: "#005f90",
        900: "#272c37",
      },
      success: {
        100: "#e1fbf3",
        200: "#00a977",
        300: "#00885c",
        700: "#006844",
      },
      warning: { 100: "#fff4de", 400: "#fca600", 700: "#d78300" },
    },
    extend: {
      boxShadow: {
        1: "0 1px 1px 0 rgba(10, 22, 70, 0.1)",
        2: "0 3px 3px -1px rgba(10, 22, 70, 0.1)",
        3: "0 6px 6px -1px rgba(10, 22, 70, 0.1)",
        4: "0 16px 16px -1px rgba(10, 22, 70, 0.1)",
        5: "0 32px 40px -2px rgba(10, 22, 70, 0.1)",
      },
      scale: {
        98: ".98",
      },
      screens: {
        "desktop-up": { min: "1440px" },
        "desktop-hd": { min: "2048px" },
        "lg-down": { max: "1024px" },
        portrait: {
          raw: "(orientation: portrait)",
        },
        landscape: {
          raw: "(orientation: landscape)",
        },
      },
      maxWidth: {
        desktop: "1440px",
        4: "16px",
      },
      minWidth: {
        5: "20px",
        4: "16px",
      },
      outline: {
        gray: ["1px dotted #050505", "4px"],
        white: ["1px dotted #ffffff", "4px"],
        red: ["1px dotted #e10a1d", "4px"],
      },
      width: {
        "1/6vw": "16.666vw",
        "1/5vw": "20vw",
        "1/4vw": "25vw",
        "1/3vw": "33.33vw",
        "1/2vw": "50vw",
        "3/4vw": "75vw",
      },
      height: {
        "1/4vh": "25vh",
        "1/3vh": "33.33vh",
        "1/2vh": "50vh",
        "2/3vh": "66.66vh",
        "3/4vh": "75vh",
        "9/10": "90%",
        initial: "initial",
        auto: "auto",
        fit: "fit-content",
      },
      keyframes: {
        slideInFromRight: {
          from: {
            transform: "translateX(20%) scale(0.85)",
            opacity: 0,
          },
          to: {
            transform: "translateX(0%) scale(1)",
            opacity: 1,
          },
        },
        progress: {
          from: {
            transform: "translateX(10%)",
          },
          to: {
            transform: "translateX(0%)",
          },
        },
      },
      animation: {
        slideInFromRight: "slideInFromRight 1s ease-in",
        pingAnimation: "ping 2s cubic-bezier(0, 0, 0.2, 1) infinite",
        progress: "progress 4s linear forwards",
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [require("tailwind-scrollbar-hide")],
}
