import React, { useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Dealer icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */

const Dealer: React.FC<IconProps> = ({ ...remainingProps }) => {
  return (
    <svg viewBox="0 0 72 72" fill="none" {...remainingProps}>
      <g clip-path="url(#clip0_1657_11493)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M35.7628 0.546204C29.8789 0.546204 23.9809 1.12027 19.5435 2.27567C17.3262 2.85299 15.4538 3.58045 14.1298 4.4669C12.803 5.35529 12.0014 6.42259 12.0014 7.66893V21.4189H5.2514H3.7514H2.7514V45.4189H-0.248596V47.9189H72.2514V45.4189H69.2514V21.6689H69.0014H66.7514H59.5241V7.66893C59.5241 6.42259 58.7226 5.35529 57.3957 4.4669C56.0717 3.58045 54.1993 2.85299 51.9821 2.27567C47.5446 1.12027 41.6467 0.546204 35.7628 0.546204ZM66.7514 45.4189V24.1689H59.5241V45.4189H66.7514ZM56.2111 45.4189V7.66893C56.2111 7.57199 56.1433 7.41952 55.9211 7.21364C55.7041 7.01253 55.3686 6.78767 54.9092 6.54988C53.9918 6.07507 52.6227 5.57044 50.8431 5.10886C47.2868 4.18651 42.1302 3.4462 35.7628 3.4462C29.3953 3.4462 24.1566 4.18651 20.5177 5.10932C18.6968 5.57112 17.2863 6.07615 16.3378 6.55172C15.8627 6.78992 15.5145 7.01528 15.2889 7.21695C15.0572 7.42406 14.9885 7.57546 14.9885 7.66893V45.4189H23.0014V26.6689H53.2514V45.4189H56.2111ZM12.0014 45.4189V23.9189H5.2514V45.4189H12.0014ZM25.9589 45.334V29.2613H36.6995V45.334H25.9589ZM39.5533 29.2613V45.334H50.5014V29.2613H39.5533Z"
          fill="#C00000"
        />
        <path
          d="M29.5175 15.6689C29.7937 15.6689 30.0175 15.4451 30.0175 15.1689C30.0175 14.8928 29.7937 14.6689 29.5175 14.6689C29.2414 14.6689 29.0175 14.8928 29.0175 15.1689C29.0175 15.4451 29.2414 15.6689 29.5175 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M27.5175 13.6689C27.7937 13.6689 28.0175 13.4451 28.0175 13.1689C28.0175 12.8928 27.7937 12.6689 27.5175 12.6689C27.2414 12.6689 27.0175 12.8928 27.0175 13.1689C27.0175 13.4451 27.2414 13.6689 27.5175 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M29.5175 13.6689C29.7937 13.6689 30.0175 13.4451 30.0175 13.1689C30.0175 12.8928 29.7937 12.6689 29.5175 12.6689C29.2414 12.6689 29.0175 12.8928 29.0175 13.1689C29.0175 13.4451 29.2414 13.6689 29.5175 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M29.5175 11.6689C29.7937 11.6689 30.0175 11.4451 30.0175 11.1689C30.0175 10.8928 29.7937 10.6689 29.5175 10.6689C29.2414 10.6689 29.0175 10.8928 29.0175 11.1689C29.0175 11.4451 29.2414 11.6689 29.5175 11.6689Z"
          fill="#CC0000"
        />
        <path
          d="M28.5175 14.6689C28.7937 14.6689 29.0175 14.4451 29.0175 14.1689C29.0175 13.8928 28.7937 13.6689 28.5175 13.6689C28.2414 13.6689 28.0175 13.8928 28.0175 14.1689C28.0175 14.4451 28.2414 14.6689 28.5175 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M28.5175 12.6689C28.7937 12.6689 29.0175 12.4451 29.0175 12.1689C29.0175 11.8928 28.7937 11.6689 28.5175 11.6689C28.2414 11.6689 28.0175 11.8928 28.0175 12.1689C28.0175 12.4451 28.2414 12.6689 28.5175 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M27.5175 11.6689C27.7937 11.6689 28.0175 11.4451 28.0175 11.1689C28.0175 10.8928 27.7937 10.6689 27.5175 10.6689C27.2414 10.6689 27.0175 10.8928 27.0175 11.1689C27.0175 11.4451 27.2414 11.6689 27.5175 11.6689Z"
          fill="#CC0000"
        />
        <path
          d="M26.5175 14.6689C26.7937 14.6689 27.0175 14.4451 27.0175 14.1689C27.0175 13.8928 26.7937 13.6689 26.5175 13.6689C26.2414 13.6689 26.0175 13.8928 26.0175 14.1689C26.0175 14.4451 26.2414 14.6689 26.5175 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M23.5175 11.6689C23.7937 11.6689 24.0175 11.4451 24.0175 11.1689C24.0175 10.8928 23.7937 10.6689 23.5175 10.6689C23.2414 10.6689 23.0175 10.8928 23.0175 11.1689C23.0175 11.4451 23.2414 11.6689 23.5175 11.6689Z"
          fill="#CC0000"
        />
        <path
          d="M27.5175 15.6689C27.7937 15.6689 28.0175 15.4451 28.0175 15.1689C28.0175 14.8928 27.7937 14.6689 27.5175 14.6689C27.2414 14.6689 27.0175 14.8928 27.0175 15.1689C27.0175 15.4451 27.2414 15.6689 27.5175 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M25.5175 13.6689C25.7937 13.6689 26.0175 13.4451 26.0175 13.1689C26.0175 12.8928 25.7937 12.6689 25.5175 12.6689C25.2414 12.6689 25.0175 12.8928 25.0175 13.1689C25.0175 13.4451 25.2414 13.6689 25.5175 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M25.5175 11.6689C25.7937 11.6689 26.0175 11.4451 26.0175 11.1689C26.0175 10.8928 25.7937 10.6689 25.5175 10.6689C25.2414 10.6689 25.0175 10.8928 25.0175 11.1689C25.0175 11.4451 25.2414 11.6689 25.5175 11.6689Z"
          fill="#CC0000"
        />
        <path
          d="M26.5175 12.6689C26.7937 12.6689 27.0175 12.4451 27.0175 12.1689C27.0175 11.8928 26.7937 11.6689 26.5175 11.6689C26.2414 11.6689 26.0175 11.8928 26.0175 12.1689C26.0175 12.4451 26.2414 12.6689 26.5175 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M24.5175 14.6689C24.7937 14.6689 25.0175 14.4451 25.0175 14.1689C25.0175 13.8928 24.7937 13.6689 24.5175 13.6689C24.2414 13.6689 24.0175 13.8928 24.0175 14.1689C24.0175 14.4451 24.2414 14.6689 24.5175 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M24.5175 12.6689C24.7937 12.6689 25.0175 12.4451 25.0175 12.1689C25.0175 11.8928 24.7937 11.6689 24.5175 11.6689C24.2414 11.6689 24.0175 11.8928 24.0175 12.1689C24.0175 12.4451 24.2414 12.6689 24.5175 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M25.5175 15.6689C25.7937 15.6689 26.0175 15.4451 26.0175 15.1689C26.0175 14.8928 25.7937 14.6689 25.5175 14.6689C25.2414 14.6689 25.0175 14.8928 25.0175 15.1689C25.0175 15.4451 25.2414 15.6689 25.5175 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M23.5014 15.6689C23.7775 15.6689 24.0014 15.4451 24.0014 15.1689C24.0014 14.8928 23.7775 14.6689 23.5014 14.6689C23.2253 14.6689 23.0014 14.8928 23.0014 15.1689C23.0014 15.4451 23.2253 15.6689 23.5014 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M21.5014 15.6689C21.7775 15.6689 22.0014 15.4451 22.0014 15.1689C22.0014 14.8928 21.7775 14.6689 21.5014 14.6689C21.2253 14.6689 21.0014 14.8928 21.0014 15.1689C21.0014 15.4451 21.2253 15.6689 21.5014 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M29.5175 17.6689C29.7937 17.6689 30.0175 17.4451 30.0175 17.1689C30.0175 16.8928 29.7937 16.6689 29.5175 16.6689C29.2414 16.6689 29.0175 16.8928 29.0175 17.1689C29.0175 17.4451 29.2414 17.6689 29.5175 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M28.5175 16.6689C28.7937 16.6689 29.0175 16.4451 29.0175 16.1689C29.0175 15.8928 28.7937 15.6689 28.5175 15.6689C28.2414 15.6689 28.0175 15.8928 28.0175 16.1689C28.0175 16.4451 28.2414 16.6689 28.5175 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M26.5175 16.6689C26.7937 16.6689 27.0175 16.4451 27.0175 16.1689C27.0175 15.8928 26.7937 15.6689 26.5175 15.6689C26.2414 15.6689 26.0175 15.8928 26.0175 16.1689C26.0175 16.4451 26.2414 16.6689 26.5175 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M24.5175 16.6689C24.7937 16.6689 25.0175 16.4451 25.0175 16.1689C25.0175 15.8928 24.7937 15.6689 24.5175 15.6689C24.2414 15.6689 24.0175 15.8928 24.0175 16.1689C24.0175 16.4451 24.2414 16.6689 24.5175 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M22.5014 16.6689C22.7775 16.6689 23.0014 16.4451 23.0014 16.1689C23.0014 15.8928 22.7775 15.6689 22.5014 15.6689C22.2253 15.6689 22.0014 15.8928 22.0014 16.1689C22.0014 16.4451 22.2253 16.6689 22.5014 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M28.5175 18.6689C28.7937 18.6689 29.0175 18.4451 29.0175 18.1689C29.0175 17.8928 28.7937 17.6689 28.5175 17.6689C28.2414 17.6689 28.0175 17.8928 28.0175 18.1689C28.0175 18.4451 28.2414 18.6689 28.5175 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M26.5175 18.6689C26.7937 18.6689 27.0175 18.4451 27.0175 18.1689C27.0175 17.8928 26.7937 17.6689 26.5175 17.6689C26.2414 17.6689 26.0175 17.8928 26.0175 18.1689C26.0175 18.4451 26.2414 18.6689 26.5175 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M24.5175 18.6689C24.7937 18.6689 25.0175 18.4451 25.0175 18.1689C25.0175 17.8928 24.7937 17.6689 24.5175 17.6689C24.2414 17.6689 24.0175 17.8928 24.0175 18.1689C24.0175 18.4451 24.2414 18.6689 24.5175 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M22.5014 18.6689C22.7775 18.6689 23.0014 18.4451 23.0014 18.1689C23.0014 17.8928 22.7775 17.6689 22.5014 17.6689C22.2253 17.6689 22.0014 17.8928 22.0014 18.1689C22.0014 18.4451 22.2253 18.6689 22.5014 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M27.5175 17.6689C27.7937 17.6689 28.0175 17.4451 28.0175 17.1689C28.0175 16.8928 27.7937 16.6689 27.5175 16.6689C27.2414 16.6689 27.0175 16.8928 27.0175 17.1689C27.0175 17.4451 27.2414 17.6689 27.5175 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M25.5175 17.6689C25.7937 17.6689 26.0175 17.4451 26.0175 17.1689C26.0175 16.8928 25.7937 16.6689 25.5175 16.6689C25.2414 16.6689 25.0175 16.8928 25.0175 17.1689C25.0175 17.4451 25.2414 17.6689 25.5175 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M23.5014 17.6689C23.7775 17.6689 24.0014 17.4451 24.0014 17.1689C24.0014 16.8928 23.7775 16.6689 23.5014 16.6689C23.2253 16.6689 23.0014 16.8928 23.0014 17.1689C23.0014 17.4451 23.2253 17.6689 23.5014 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M21.5014 17.6689C21.7775 17.6689 22.0014 17.4451 22.0014 17.1689C22.0014 16.8928 21.7775 16.6689 21.5014 16.6689C21.2253 16.6689 21.0014 16.8928 21.0014 17.1689C21.0014 17.4451 21.2253 17.6689 21.5014 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M29.5175 19.6689C29.7937 19.6689 30.0175 19.4451 30.0175 19.1689C30.0175 18.8928 29.7937 18.6689 29.5175 18.6689C29.2414 18.6689 29.0175 18.8928 29.0175 19.1689C29.0175 19.4451 29.2414 19.6689 29.5175 19.6689Z"
          fill="#CC0000"
        />
        <path
          d="M27.5175 19.6689C27.7937 19.6689 28.0175 19.4451 28.0175 19.1689C28.0175 18.8928 27.7937 18.6689 27.5175 18.6689C27.2414 18.6689 27.0175 18.8928 27.0175 19.1689C27.0175 19.4451 27.2414 19.6689 27.5175 19.6689Z"
          fill="#CC0000"
        />
        <path
          d="M25.5175 19.6689C25.7937 19.6689 26.0175 19.4451 26.0175 19.1689C26.0175 18.8928 25.7937 18.6689 25.5175 18.6689C25.2414 18.6689 25.0175 18.8928 25.0175 19.1689C25.0175 19.4451 25.2414 19.6689 25.5175 19.6689Z"
          fill="#CC0000"
        />
        <path
          d="M23.5014 19.6689C23.7775 19.6689 24.0014 19.4451 24.0014 19.1689C24.0014 18.8928 23.7775 18.6689 23.5014 18.6689C23.2253 18.6689 23.0014 18.8928 23.0014 19.1689C23.0014 19.4451 23.2253 19.6689 23.5014 19.6689Z"
          fill="#CC0000"
        />
        <path
          d="M23.5014 13.6689C23.7775 13.6689 24.0014 13.4451 24.0014 13.1689C24.0014 12.8928 23.7775 12.6689 23.5014 12.6689C23.2253 12.6689 23.0014 12.8928 23.0014 13.1689C23.0014 13.4451 23.2253 13.6689 23.5014 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M22.5014 12.6689C22.7775 12.6689 23.0014 12.4451 23.0014 12.1689C23.0014 11.8928 22.7775 11.6689 22.5014 11.6689C22.2253 11.6689 22.0014 11.8928 22.0014 12.1689C22.0014 12.4451 22.2253 12.6689 22.5014 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M22.5014 14.6689C22.7775 14.6689 23.0014 14.4451 23.0014 14.1689C23.0014 13.8928 22.7775 13.6689 22.5014 13.6689C22.2253 13.6689 22.0014 13.8928 22.0014 14.1689C22.0014 14.4451 22.2253 14.6689 22.5014 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M32.5175 12.6689C32.7937 12.6689 33.0175 12.4451 33.0175 12.1689C33.0175 11.8928 32.7937 11.6689 32.5175 11.6689C32.2414 11.6689 32.0175 11.8928 32.0175 12.1689C32.0175 12.4451 32.2414 12.6689 32.5175 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M31.5175 13.6689C31.7937 13.6689 32.0175 13.4451 32.0175 13.1689C32.0175 12.8928 31.7937 12.6689 31.5175 12.6689C31.2414 12.6689 31.0175 12.8928 31.0175 13.1689C31.0175 13.4451 31.2414 13.6689 31.5175 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M33.5175 13.6689C33.7937 13.6689 34.0175 13.4451 34.0175 13.1689C34.0175 12.8928 33.7937 12.6689 33.5175 12.6689C33.2414 12.6689 33.0175 12.8928 33.0175 13.1689C33.0175 13.4451 33.2414 13.6689 33.5175 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M30.5175 14.6689C30.7937 14.6689 31.0175 14.4451 31.0175 14.1689C31.0175 13.8928 30.7937 13.6689 30.5175 13.6689C30.2414 13.6689 30.0175 13.8928 30.0175 14.1689C30.0175 14.4451 30.2414 14.6689 30.5175 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M32.5175 14.6689C32.7937 14.6689 33.0175 14.4451 33.0175 14.1689C33.0175 13.8928 32.7937 13.6689 32.5175 13.6689C32.2414 13.6689 32.0175 13.8928 32.0175 14.1689C32.0175 14.4451 32.2414 14.6689 32.5175 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M31.5175 15.6689C31.7937 15.6689 32.0175 15.4451 32.0175 15.1689C32.0175 14.8928 31.7937 14.6689 31.5175 14.6689C31.2414 14.6689 31.0175 14.8928 31.0175 15.1689C31.0175 15.4451 31.2414 15.6689 31.5175 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M30.5175 16.6689C30.7937 16.6689 31.0175 16.4451 31.0175 16.1689C31.0175 15.8928 30.7937 15.6689 30.5175 15.6689C30.2414 15.6689 30.0175 15.8928 30.0175 16.1689C30.0175 16.4451 30.2414 16.6689 30.5175 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M32.5175 16.6689C32.7937 16.6689 33.0175 16.4451 33.0175 16.1689C33.0175 15.8928 32.7937 15.6689 32.5175 15.6689C32.2414 15.6689 32.0175 15.8928 32.0175 16.1689C32.0175 16.4451 32.2414 16.6689 32.5175 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M31.5175 17.6689C31.7937 17.6689 32.0175 17.4451 32.0175 17.1689C32.0175 16.8928 31.7937 16.6689 31.5175 16.6689C31.2414 16.6689 31.0175 16.8928 31.0175 17.1689C31.0175 17.4451 31.2414 17.6689 31.5175 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M33.5175 17.6689C33.7937 17.6689 34.0175 17.4451 34.0175 17.1689C34.0175 16.8928 33.7937 16.6689 33.5175 16.6689C33.2414 16.6689 33.0175 16.8928 33.0175 17.1689C33.0175 17.4451 33.2414 17.6689 33.5175 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M30.5175 18.6689C30.7937 18.6689 31.0175 18.4451 31.0175 18.1689C31.0175 17.8928 30.7937 17.6689 30.5175 17.6689C30.2414 17.6689 30.0175 17.8928 30.0175 18.1689C30.0175 18.4451 30.2414 18.6689 30.5175 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M32.5175 18.6689C32.7937 18.6689 33.0175 18.4451 33.0175 18.1689C33.0175 17.8928 32.7937 17.6689 32.5175 17.6689C32.2414 17.6689 32.0175 17.8928 32.0175 18.1689C32.0175 18.4451 32.2414 18.6689 32.5175 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M33.5175 15.6689C33.7937 15.6689 34.0175 15.4451 34.0175 15.1689C34.0175 14.8928 33.7937 14.6689 33.5175 14.6689C33.2414 14.6689 33.0175 14.8928 33.0175 15.1689C33.0175 15.4451 33.2414 15.6689 33.5175 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M20.5014 16.6689C20.7775 16.6689 21.0014 16.4451 21.0014 16.1689C21.0014 15.8928 20.7775 15.6689 20.5014 15.6689C20.2253 15.6689 20.0014 15.8928 20.0014 16.1689C20.0014 16.4451 20.2253 16.6689 20.5014 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M20.5014 14.6689C20.7775 14.6689 21.0014 14.4451 21.0014 14.1689C21.0014 13.8928 20.7775 13.6689 20.5014 13.6689C20.2253 13.6689 20.0014 13.8928 20.0014 14.1689C20.0014 14.4451 20.2253 14.6689 20.5014 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M30.5175 12.6689C30.7937 12.6689 31.0175 12.4451 31.0175 12.1689C31.0175 11.8928 30.7937 11.6689 30.5175 11.6689C30.2414 11.6689 30.0175 11.8928 30.0175 12.1689C30.0175 12.4451 30.2414 12.6689 30.5175 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M21.5014 13.6689C21.7775 13.6689 22.0014 13.4451 22.0014 13.1689C22.0014 12.8928 21.7775 12.6689 21.5014 12.6689C21.2253 12.6689 21.0014 12.8928 21.0014 13.1689C21.0014 13.4451 21.2253 13.6689 21.5014 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M31.5175 15.6689C31.7937 15.6689 32.0175 15.4451 32.0175 15.1689C32.0175 14.8928 31.7937 14.6689 31.5175 14.6689C31.2414 14.6689 31.0175 14.8928 31.0175 15.1689C31.0175 15.4451 31.2414 15.6689 31.5175 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M29.5175 13.6689C29.7937 13.6689 30.0175 13.4451 30.0175 13.1689C30.0175 12.8928 29.7937 12.6689 29.5175 12.6689C29.2414 12.6689 29.0175 12.8928 29.0175 13.1689C29.0175 13.4451 29.2414 13.6689 29.5175 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M31.5175 13.6689C31.7937 13.6689 32.0175 13.4451 32.0175 13.1689C32.0175 12.8928 31.7937 12.6689 31.5175 12.6689C31.2414 12.6689 31.0175 12.8928 31.0175 13.1689C31.0175 13.4451 31.2414 13.6689 31.5175 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M31.5175 11.6689C31.7937 11.6689 32.0175 11.4451 32.0175 11.1689C32.0175 10.8928 31.7937 10.6689 31.5175 10.6689C31.2414 10.6689 31.0175 10.8928 31.0175 11.1689C31.0175 11.4451 31.2414 11.6689 31.5175 11.6689Z"
          fill="#CC0000"
        />
        <path
          d="M30.5175 14.6689C30.7937 14.6689 31.0175 14.4451 31.0175 14.1689C31.0175 13.8928 30.7937 13.6689 30.5175 13.6689C30.2414 13.6689 30.0175 13.8928 30.0175 14.1689C30.0175 14.4451 30.2414 14.6689 30.5175 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M30.5175 12.6689C30.7937 12.6689 31.0175 12.4451 31.0175 12.1689C31.0175 11.8928 30.7937 11.6689 30.5175 11.6689C30.2414 11.6689 30.0175 11.8928 30.0175 12.1689C30.0175 12.4451 30.2414 12.6689 30.5175 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M29.5175 11.6689C29.7937 11.6689 30.0175 11.4451 30.0175 11.1689C30.0175 10.8928 29.7937 10.6689 29.5175 10.6689C29.2414 10.6689 29.0175 10.8928 29.0175 11.1689C29.0175 11.4451 29.2414 11.6689 29.5175 11.6689Z"
          fill="#CC0000"
        />
        <path
          d="M28.5175 14.6689C28.7937 14.6689 29.0175 14.4451 29.0175 14.1689C29.0175 13.8928 28.7937 13.6689 28.5175 13.6689C28.2414 13.6689 28.0175 13.8928 28.0175 14.1689C28.0175 14.4451 28.2414 14.6689 28.5175 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M25.5175 11.6689C25.7937 11.6689 26.0175 11.4451 26.0175 11.1689C26.0175 10.8928 25.7937 10.6689 25.5175 10.6689C25.2414 10.6689 25.0175 10.8928 25.0175 11.1689C25.0175 11.4451 25.2414 11.6689 25.5175 11.6689Z"
          fill="#CC0000"
        />
        <path
          d="M29.5175 15.6689C29.7937 15.6689 30.0175 15.4451 30.0175 15.1689C30.0175 14.8928 29.7937 14.6689 29.5175 14.6689C29.2414 14.6689 29.0175 14.8928 29.0175 15.1689C29.0175 15.4451 29.2414 15.6689 29.5175 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M27.5175 13.6689C27.7937 13.6689 28.0175 13.4451 28.0175 13.1689C28.0175 12.8928 27.7937 12.6689 27.5175 12.6689C27.2414 12.6689 27.0175 12.8928 27.0175 13.1689C27.0175 13.4451 27.2414 13.6689 27.5175 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M27.5175 11.6689C27.7937 11.6689 28.0175 11.4451 28.0175 11.1689C28.0175 10.8928 27.7937 10.6689 27.5175 10.6689C27.2414 10.6689 27.0175 10.8928 27.0175 11.1689C27.0175 11.4451 27.2414 11.6689 27.5175 11.6689Z"
          fill="#CC0000"
        />
        <path
          d="M28.5175 12.6689C28.7937 12.6689 29.0175 12.4451 29.0175 12.1689C29.0175 11.8928 28.7937 11.6689 28.5175 11.6689C28.2414 11.6689 28.0175 11.8928 28.0175 12.1689C28.0175 12.4451 28.2414 12.6689 28.5175 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M26.5175 14.6689C26.7937 14.6689 27.0175 14.4451 27.0175 14.1689C27.0175 13.8928 26.7937 13.6689 26.5175 13.6689C26.2414 13.6689 26.0175 13.8928 26.0175 14.1689C26.0175 14.4451 26.2414 14.6689 26.5175 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M26.5175 12.6689C26.7937 12.6689 27.0175 12.4451 27.0175 12.1689C27.0175 11.8928 26.7937 11.6689 26.5175 11.6689C26.2414 11.6689 26.0175 11.8928 26.0175 12.1689C26.0175 12.4451 26.2414 12.6689 26.5175 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M27.5175 15.6689C27.7937 15.6689 28.0175 15.4451 28.0175 15.1689C28.0175 14.8928 27.7937 14.6689 27.5175 14.6689C27.2414 14.6689 27.0175 14.8928 27.0175 15.1689C27.0175 15.4451 27.2414 15.6689 27.5175 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M25.5014 15.6689C25.7775 15.6689 26.0014 15.4451 26.0014 15.1689C26.0014 14.8928 25.7775 14.6689 25.5014 14.6689C25.2253 14.6689 25.0014 14.8928 25.0014 15.1689C25.0014 15.4451 25.2253 15.6689 25.5014 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M23.5014 15.6689C23.7775 15.6689 24.0014 15.4451 24.0014 15.1689C24.0014 14.8928 23.7775 14.6689 23.5014 14.6689C23.2253 14.6689 23.0014 14.8928 23.0014 15.1689C23.0014 15.4451 23.2253 15.6689 23.5014 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M31.5175 17.6689C31.7937 17.6689 32.0175 17.4451 32.0175 17.1689C32.0175 16.8928 31.7937 16.6689 31.5175 16.6689C31.2414 16.6689 31.0175 16.8928 31.0175 17.1689C31.0175 17.4451 31.2414 17.6689 31.5175 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M30.5175 16.6689C30.7937 16.6689 31.0175 16.4451 31.0175 16.1689C31.0175 15.8928 30.7937 15.6689 30.5175 15.6689C30.2414 15.6689 30.0175 15.8928 30.0175 16.1689C30.0175 16.4451 30.2414 16.6689 30.5175 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M28.5175 16.6689C28.7937 16.6689 29.0175 16.4451 29.0175 16.1689C29.0175 15.8928 28.7937 15.6689 28.5175 15.6689C28.2414 15.6689 28.0175 15.8928 28.0175 16.1689C28.0175 16.4451 28.2414 16.6689 28.5175 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M26.5175 16.6689C26.7937 16.6689 27.0175 16.4451 27.0175 16.1689C27.0175 15.8928 26.7937 15.6689 26.5175 15.6689C26.2414 15.6689 26.0175 15.8928 26.0175 16.1689C26.0175 16.4451 26.2414 16.6689 26.5175 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M24.5014 16.6689C24.7775 16.6689 25.0014 16.4451 25.0014 16.1689C25.0014 15.8928 24.7775 15.6689 24.5014 15.6689C24.2253 15.6689 24.0014 15.8928 24.0014 16.1689C24.0014 16.4451 24.2253 16.6689 24.5014 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M30.5175 18.6689C30.7937 18.6689 31.0175 18.4451 31.0175 18.1689C31.0175 17.8928 30.7937 17.6689 30.5175 17.6689C30.2414 17.6689 30.0175 17.8928 30.0175 18.1689C30.0175 18.4451 30.2414 18.6689 30.5175 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M28.5175 18.6689C28.7937 18.6689 29.0175 18.4451 29.0175 18.1689C29.0175 17.8928 28.7937 17.6689 28.5175 17.6689C28.2414 17.6689 28.0175 17.8928 28.0175 18.1689C28.0175 18.4451 28.2414 18.6689 28.5175 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M26.5175 18.6689C26.7937 18.6689 27.0175 18.4451 27.0175 18.1689C27.0175 17.8928 26.7937 17.6689 26.5175 17.6689C26.2414 17.6689 26.0175 17.8928 26.0175 18.1689C26.0175 18.4451 26.2414 18.6689 26.5175 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M24.5014 18.6689C24.7775 18.6689 25.0014 18.4451 25.0014 18.1689C25.0014 17.8928 24.7775 17.6689 24.5014 17.6689C24.2253 17.6689 24.0014 17.8928 24.0014 18.1689C24.0014 18.4451 24.2253 18.6689 24.5014 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M29.5175 17.6689C29.7937 17.6689 30.0175 17.4451 30.0175 17.1689C30.0175 16.8928 29.7937 16.6689 29.5175 16.6689C29.2414 16.6689 29.0175 16.8928 29.0175 17.1689C29.0175 17.4451 29.2414 17.6689 29.5175 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M27.5175 17.6689C27.7937 17.6689 28.0175 17.4451 28.0175 17.1689C28.0175 16.8928 27.7937 16.6689 27.5175 16.6689C27.2414 16.6689 27.0175 16.8928 27.0175 17.1689C27.0175 17.4451 27.2414 17.6689 27.5175 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M25.5014 17.6689C25.7775 17.6689 26.0014 17.4451 26.0014 17.1689C26.0014 16.8928 25.7775 16.6689 25.5014 16.6689C25.2253 16.6689 25.0014 16.8928 25.0014 17.1689C25.0014 17.4451 25.2253 17.6689 25.5014 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M23.5014 17.6689C23.7775 17.6689 24.0014 17.4451 24.0014 17.1689C24.0014 16.8928 23.7775 16.6689 23.5014 16.6689C23.2253 16.6689 23.0014 16.8928 23.0014 17.1689C23.0014 17.4451 23.2253 17.6689 23.5014 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M31.5175 19.6689C31.7937 19.6689 32.0175 19.4451 32.0175 19.1689C32.0175 18.8928 31.7937 18.6689 31.5175 18.6689C31.2414 18.6689 31.0175 18.8928 31.0175 19.1689C31.0175 19.4451 31.2414 19.6689 31.5175 19.6689Z"
          fill="#CC0000"
        />
        <path
          d="M29.5175 19.6689C29.7937 19.6689 30.0175 19.4451 30.0175 19.1689C30.0175 18.8928 29.7937 18.6689 29.5175 18.6689C29.2414 18.6689 29.0175 18.8928 29.0175 19.1689C29.0175 19.4451 29.2414 19.6689 29.5175 19.6689Z"
          fill="#CC0000"
        />
        <path
          d="M27.5175 19.6689C27.7937 19.6689 28.0175 19.4451 28.0175 19.1689C28.0175 18.8928 27.7937 18.6689 27.5175 18.6689C27.2414 18.6689 27.0175 18.8928 27.0175 19.1689C27.0175 19.4451 27.2414 19.6689 27.5175 19.6689Z"
          fill="#CC0000"
        />
        <path
          d="M25.5014 19.6689C25.7775 19.6689 26.0014 19.4451 26.0014 19.1689C26.0014 18.8928 25.7775 18.6689 25.5014 18.6689C25.2253 18.6689 25.0014 18.8928 25.0014 19.1689C25.0014 19.4451 25.2253 19.6689 25.5014 19.6689Z"
          fill="#CC0000"
        />
        <path
          d="M25.5014 13.6689C25.7775 13.6689 26.0014 13.4451 26.0014 13.1689C26.0014 12.8928 25.7775 12.6689 25.5014 12.6689C25.2253 12.6689 25.0014 12.8928 25.0014 13.1689C25.0014 13.4451 25.2253 13.6689 25.5014 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M24.5014 12.6689C24.7775 12.6689 25.0014 12.4451 25.0014 12.1689C25.0014 11.8928 24.7775 11.6689 24.5014 11.6689C24.2253 11.6689 24.0014 11.8928 24.0014 12.1689C24.0014 12.4451 24.2253 12.6689 24.5014 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M24.5014 14.6689C24.7775 14.6689 25.0014 14.4451 25.0014 14.1689C25.0014 13.8928 24.7775 13.6689 24.5014 13.6689C24.2253 13.6689 24.0014 13.8928 24.0014 14.1689C24.0014 14.4451 24.2253 14.6689 24.5014 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M34.5175 12.6689C34.7937 12.6689 35.0175 12.4451 35.0175 12.1689C35.0175 11.8928 34.7937 11.6689 34.5175 11.6689C34.2414 11.6689 34.0175 11.8928 34.0175 12.1689C34.0175 12.4451 34.2414 12.6689 34.5175 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M33.5175 13.6689C33.7937 13.6689 34.0175 13.4451 34.0175 13.1689C34.0175 12.8928 33.7937 12.6689 33.5175 12.6689C33.2414 12.6689 33.0175 12.8928 33.0175 13.1689C33.0175 13.4451 33.2414 13.6689 33.5175 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M35.5175 13.6689C35.7937 13.6689 36.0175 13.4451 36.0175 13.1689C36.0175 12.8928 35.7937 12.6689 35.5175 12.6689C35.2414 12.6689 35.0175 12.8928 35.0175 13.1689C35.0175 13.4451 35.2414 13.6689 35.5175 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M32.5175 14.6689C32.7937 14.6689 33.0175 14.4451 33.0175 14.1689C33.0175 13.8928 32.7937 13.6689 32.5175 13.6689C32.2414 13.6689 32.0175 13.8928 32.0175 14.1689C32.0175 14.4451 32.2414 14.6689 32.5175 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M34.5175 14.6689C34.7937 14.6689 35.0175 14.4451 35.0175 14.1689C35.0175 13.8928 34.7937 13.6689 34.5175 13.6689C34.2414 13.6689 34.0175 13.8928 34.0175 14.1689C34.0175 14.4451 34.2414 14.6689 34.5175 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M33.5175 15.6689C33.7937 15.6689 34.0175 15.4451 34.0175 15.1689C34.0175 14.8928 33.7937 14.6689 33.5175 14.6689C33.2414 14.6689 33.0175 14.8928 33.0175 15.1689C33.0175 15.4451 33.2414 15.6689 33.5175 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M32.5175 16.6689C32.7937 16.6689 33.0175 16.4451 33.0175 16.1689C33.0175 15.8928 32.7937 15.6689 32.5175 15.6689C32.2414 15.6689 32.0175 15.8928 32.0175 16.1689C32.0175 16.4451 32.2414 16.6689 32.5175 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M34.5175 16.6689C34.7937 16.6689 35.0175 16.4451 35.0175 16.1689C35.0175 15.8928 34.7937 15.6689 34.5175 15.6689C34.2414 15.6689 34.0175 15.8928 34.0175 16.1689C34.0175 16.4451 34.2414 16.6689 34.5175 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M33.5175 17.6689C33.7937 17.6689 34.0175 17.4451 34.0175 17.1689C34.0175 16.8928 33.7937 16.6689 33.5175 16.6689C33.2414 16.6689 33.0175 16.8928 33.0175 17.1689C33.0175 17.4451 33.2414 17.6689 33.5175 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M35.5175 17.6689C35.7937 17.6689 36.0175 17.4451 36.0175 17.1689C36.0175 16.8928 35.7937 16.6689 35.5175 16.6689C35.2414 16.6689 35.0175 16.8928 35.0175 17.1689C35.0175 17.4451 35.2414 17.6689 35.5175 17.6689Z"
          fill="#CC0000"
        />
        <path
          d="M32.5175 18.6689C32.7937 18.6689 33.0175 18.4451 33.0175 18.1689C33.0175 17.8928 32.7937 17.6689 32.5175 17.6689C32.2414 17.6689 32.0175 17.8928 32.0175 18.1689C32.0175 18.4451 32.2414 18.6689 32.5175 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M34.5175 18.6689C34.7937 18.6689 35.0175 18.4451 35.0175 18.1689C35.0175 17.8928 34.7937 17.6689 34.5175 17.6689C34.2414 17.6689 34.0175 17.8928 34.0175 18.1689C34.0175 18.4451 34.2414 18.6689 34.5175 18.6689Z"
          fill="#CC0000"
        />
        <path
          d="M35.5175 15.6689C35.7937 15.6689 36.0175 15.4451 36.0175 15.1689C36.0175 14.8928 35.7937 14.6689 35.5175 14.6689C35.2414 14.6689 35.0175 14.8928 35.0175 15.1689C35.0175 15.4451 35.2414 15.6689 35.5175 15.6689Z"
          fill="#CC0000"
        />
        <path
          d="M22.5014 16.6689C22.7775 16.6689 23.0014 16.4451 23.0014 16.1689C23.0014 15.8928 22.7775 15.6689 22.5014 15.6689C22.2253 15.6689 22.0014 15.8928 22.0014 16.1689C22.0014 16.4451 22.2253 16.6689 22.5014 16.6689Z"
          fill="#CC0000"
        />
        <path
          d="M22.5014 14.6689C22.7775 14.6689 23.0014 14.4451 23.0014 14.1689C23.0014 13.8928 22.7775 13.6689 22.5014 13.6689C22.2253 13.6689 22.0014 13.8928 22.0014 14.1689C22.0014 14.4451 22.2253 14.6689 22.5014 14.6689Z"
          fill="#CC0000"
        />
        <path
          d="M32.5175 12.6689C32.7937 12.6689 33.0175 12.4451 33.0175 12.1689C33.0175 11.8928 32.7937 11.6689 32.5175 11.6689C32.2414 11.6689 32.0175 11.8928 32.0175 12.1689C32.0175 12.4451 32.2414 12.6689 32.5175 12.6689Z"
          fill="#CC0000"
        />
        <path
          d="M23.5014 13.6689C23.7775 13.6689 24.0014 13.4451 24.0014 13.1689C24.0014 12.8928 23.7775 12.6689 23.5014 12.6689C23.2253 12.6689 23.0014 12.8928 23.0014 13.1689C23.0014 13.4451 23.2253 13.6689 23.5014 13.6689Z"
          fill="#CC0000"
        />
        <path
          d="M36.0014 15.1689C36.0014 16.5376 35.1914 17.8881 33.6542 18.9398C32.1219 19.9883 29.9496 20.6689 27.5014 20.6689C25.0533 20.6689 22.8809 19.9883 21.3486 18.9398C19.8114 17.8881 19.0014 16.5376 19.0014 15.1689C19.0014 13.8003 19.8114 12.4498 21.3486 11.3981C22.8809 10.3496 25.0533 9.66895 27.5014 9.66895C29.9496 9.66895 32.1219 10.3496 33.6542 11.3981C35.1914 12.4498 36.0014 13.8003 36.0014 15.1689Z"
          stroke="#CC0000"
          stroke-width="2"
        />
        <rect x="38.0014" y="19.6689" width="15" height="3" fill="#C00000" />
      </g>
      <defs>
        <clipPath id="clip0_1657_11493">
          <rect
            width="72"
            height="46.8"
            fill="white"
            transform="translate(0.00140381 0.66893)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Dealer
