import type { ModelComparisonState } from "./modelComparison.d.ts"
import { ACTION_TYPES } from "./actions"

export const defaultState: ModelComparisonState = {
  modalOpen: false,
  canSelectAnotherTrim: true,
  model: "", // also referred to as series
  year: "",
  trims: [],
  selectedTrim: null, // This is used when the user wants to change the trim of a model
  selectedModel: null, // This is used when the user wants to change models
}

const modelComparisonReducer = (
  state: ModelComparisonState,
  action: { type: ACTION_TYPES; payload?: any }
) => {
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_VEHICLE_SELECT_MODAL: {
      return {
        ...state,
        modalOpen: !state.modalOpen,
        selectedTrim: null,
      }
    }
    case ACTION_TYPES.ADD_TRIM: {
      const newTrims = Array.isArray(action.payload.trim)
        ? action.payload.trim
        : [action.payload.trim]

      return {
        ...state,
        modalOpen: false,
        model: action.payload.series,
        year: action.payload.year,
        trims: [...state.trims, ...newTrims],
      }
    }
    case ACTION_TYPES.REMOVE_TRIM: {
      if (state.trims.length === 3) {
        return {
          ...state,
          canSelectAnotherTrim: true,
          trims: state.trims.filter(
            (model: string) => model !== action.payload.trim
          ),
        }
      } else if (state.trims.length === 1) {
        return {
          ...state,
          canSelectAnotherTrim: true,
          model: "",
          year: "",
          trims: [],
        }
      } else {
        return {
          ...state,
          canSelectAnotherTrim: true,
          trims: state.trims.filter(
            (model: string) => model !== action.payload.trim
          ),
        }
      }
    }
    case ACTION_TYPES.CHANGE_TRIM: {
      return {
        ...state,
        modalOpen: !state.modalOpen,
        selectedTrim: action.payload.trim,
      }
    }
    case ACTION_TYPES.UPDATE_TRIM: {
      const trimIndex = state.trims.findIndex(
        (model: string) => model === state.selectedTrim
      )
      let newTrims = [...state.trims]
      if (trimIndex !== -1) {
        newTrims[trimIndex] = action.payload.trim
      }
      const { selectedTrim, ...remainingState } = state

      return {
        ...remainingState,
        modalOpen: !state.modalOpen,
        selectedTrim: defaultState.selectedTrim,
        trims: newTrims,
      }
    }
    case ACTION_TYPES.CHANGE_MODEL: {
      return {
        ...state,
        modalOpen: !state.modalOpen,
        selectedModel: action.payload.trim,
      }
    }
    case ACTION_TYPES.UPDATE_MODEL: {
      const { selectedModel, selectedTrim, model, trims, ...remainingState } =
        state
      return {
        ...remainingState,
        selectedModel: null,
        selectedTrim: null,
        trims: [],
        model: "",
      }
    }
    default:
      throw new Error()
  }
}

export default modelComparisonReducer
