import React, { useState } from "react"
import { LocalStorageValue } from "../clients/LocalStorageClient.d"
import { LocalStorageClient } from "../clients/LocalStorageClient"

const useLocalStorage = (
  key: string
): [
  LocalStorageValue,
  (arg: LocalStorageValue) => void,
  (arg: LocalStorageValue) => void
] => {
  const readCurrentValue = () => LocalStorageClient.read(key)
  const [value, setValue] = useState<LocalStorageValue>(readCurrentValue())

  const writeValue = (value: LocalStorageValue) => {
    const newValue = LocalStorageClient.write(key, value)
    setValue(newValue)
  }

  const putValue = (value: LocalStorageValue) => {
    const newValue = LocalStorageClient.put(key, value)
    setValue(newValue)
  }

  return [value, writeValue, putValue]
}

export default useLocalStorage
