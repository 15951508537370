export enum ACTION_TYPES {
  TOGGLE_VEHICLE_SELECT_MODAL,
  ADD_TRIM,
  REMOVE_TRIM,
  CHANGE_TRIM,
  UPDATE_TRIM,
  CHANGE_MODEL,
  UPDATE_MODEL,
}

export const toggleVehicleSelectModal = () => ({
  type: ACTION_TYPES.TOGGLE_VEHICLE_SELECT_MODAL,
})
export const addTrim = (
  series: string,
  year: string | number,
  trim: string
) => ({
  type: ACTION_TYPES.ADD_TRIM,
  payload: {
    series,
    year,
    trim,
  },
})
export const removeTrim = (trim: string) => ({
  type: ACTION_TYPES.REMOVE_TRIM,
  payload: {
    trim,
  },
})
export const changeTrim = (trim: string) => ({
  type: ACTION_TYPES.CHANGE_TRIM,
  payload: {
    trim,
  },
})
export const updateTrim = (trim: string) => ({
  type: ACTION_TYPES.UPDATE_TRIM,
  payload: {
    trim,
  },
})
export const changeModel = (trim: string) => ({
  type: ACTION_TYPES.CHANGE_MODEL,
  payload: {
    trim,
  },
})
export const updateModel = () => ({
  type: ACTION_TYPES.UPDATE_MODEL,
})

export default {
  toggleVehicleSelectModal,
  addTrim,
  removeTrim,
  changeTrim,
  updateTrim,
  updateModel,
  changeModel,
}
