import { LocalStorageClient } from "../../clients/LocalStorageClient"
import { Dealer } from "../../global"

export enum ACTION_TYPES {
  SET_ZIP,
  SET_LATLNG,
  SET_CITY,
  SET_DEALERS,
  ADD_DEALERS,
  SET_STATE,
  SET_SELECTED_DEALER,
  REMOVE_SELECTED_DEALER,
  TOGGLE_DEALERS_LOADING,
  SET_MULTIPLE,
  SET_RADIUS,
  SET_OUT_OF_RANGE,
  SET_FETCHING_LOCATION_DATA,
  SET_ERROR_MESSAGE,
}

export const setZip = (zip: number | string) => ({
  type: ACTION_TYPES.SET_ZIP,
  payload: zip,
})

export const setRadius = (radius: number) => ({
  type: ACTION_TYPES.SET_RADIUS,
  payload: radius,
})

export const setDealers = (dealers: Dealer[]) => ({
  type: ACTION_TYPES.SET_DEALERS,
  payload: dealers,
})

export const toggleDealersLoading = () => ({
  type: ACTION_TYPES.TOGGLE_DEALERS_LOADING,
})

export const setMultiple = (payload: any) => ({
  type: ACTION_TYPES.SET_MULTIPLE,
  payload: payload,
})

export const setSelectedDealer = (dealerId: number) => ({
  type: ACTION_TYPES.SET_SELECTED_DEALER,
  payload: dealerId,
})

export const setOutOfRange = (
  value: boolean,
  zip: string,
  state: string,
  user_ip: string
) => ({
  type: ACTION_TYPES.SET_OUT_OF_RANGE,
  payload: { value, zip, state, user_ip },
})

export const setFetchingLocationData = (value: boolean) => ({
  type: ACTION_TYPES.SET_FETCHING_LOCATION_DATA,
  payload: value,
})

export const setErrorMessage = (message: string) => ({
  type: ACTION_TYPES.SET_ERROR_MESSAGE,
  payload: message,
})

export default { setZip, setDealers }
